import React from "react";
import SearchResultPage from "../../../templates/search-results-template";

const SearchResultResiSales = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/property/for-sale/" 
                pcategorytype="residential" 
                multipcategorytype = {['residential']}
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype= {['for-sale','sold-stc','sold', 'under-offer']}
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                psearch="Sales"
                location={location}
            />
        </React.Fragment>
    );
};

export default SearchResultResiSales;
